.parallax-container {
  margin-top: -100px;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url('/public/cabinet4-lg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

/* Mobile styles */
@media (max-width: 767px) {
  .parallax-container {
    background-image: url('/public/cabinet4-md.jpg');
  }
}