body {
  margin: 0;
  font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 300;  /* Lighter weight */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.debug {
  border-width: 1px;
  border-style: dashed;
}

.blue
{
  border-color: blue;
}

.black
{
  border-color: black;
}

.red
{
  border-color: red;
}

.green
{
  border-color: green;
}

.gold {
  color: #D7A148;
}