.image {
    margin-left: 2rem; /* Utilise rem pour l'espacement */
    float: right;
    padding-right: 20px;
    width: 100%;
    border-radius: 50%;
    border-style: solid;
    border-color: #D7A148;
    border-width: 1px;
}

/* Media query for screens smaller than or equal to 768px */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .image {
        margin-left: 0;
        margin-top: 1rem;
        border-radius: 50%;
    }
}
