.stickyHeader {
  /* background-color: rgba(0, 0, 0, 0.7); */
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.normalHeader {
  /* background-color: rgba(0, 0, 0, 0.7); */
  position:relative;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  width: 80px; 
  height: auto;
  margin-right: 20px; /* Add some spacing between the logo and the site title image */
}

.siteTitleImage {
  width: 370px; 
  height: auto;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 6px;
}

.menu li a {
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  color: #D7A148;
  font-weight: 300;
  font-size: 24px;
}

.menu li a:hover {
  background-color: #b47d38;
  color: #fff;
}

.menuLink {
  color: inherit;
  text-decoration: none;
  width: 100%;
  display: block;
  padding: 8px 16px;
}

.menuLink:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
