.container {
    display: flex;
    flex-direction: row;
    justify-content: center;  
    align-items: center;      
    margin: auto;             
  }

.column {
    flex: 1;
    padding: 20px;
}

