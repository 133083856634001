.container {
    display: flex;
    flex-direction: column;
  }
  
  .aurabeauty, .luxuryesthetics {
    color: #D7A148;
    line-height: 4px;
  }

  .aurabeauty  {
    font-family: Allura;
    font-size: 72px;
  }

  .luxuryesthetics {
    font-size: 24px;
    position: relative;
    left: 140px;
    top:26px;
    font-weight: 100;
  }
  
  