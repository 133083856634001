.footer {
    padding: 5em 0;
    background: #f3f3f3;
}

.container, .legalRow {
    width: 100%;
    max-width: 1300px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
}

.title {
    padding-bottom: 30px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.row {
    max-width: 350px;;
}

.row div a {
    padding-top: 20px;
    padding-right: 10px;;
}

.iconRow {
    display: flex;
    align-items: center;
    line-height: 35px;
}

.iconRow span {
    padding-left: 10px;
}

/* show messages for 5 seconds */
.message {
    transition: opacity 1s ease-in-out;
    opacity: 1;
  }
  
  .message.fade {
    opacity: 0;
  }

/* end messages */  

.copyrightRow {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    /*background-color:  your preferred color */
  }
  
  .copyright, .legalLeft, .legalRight {
    /* additional styling, if needed */
    font-size: 18px;
    color: gray;
  }
  
  .legalLeft, .legalRight {
    cursor: pointer;
    /* additional styling */
  }

  .hideOnMobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
  
  

